exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bill-js": () => import("./../../../src/pages/bill.js" /* webpackChunkName: "component---src-pages-bill-js" */),
  "component---src-pages-bills-js": () => import("./../../../src/pages/bills.js" /* webpackChunkName: "component---src-pages-bills-js" */),
  "component---src-pages-chamber-js": () => import("./../../../src/pages/chamber.js" /* webpackChunkName: "component---src-pages-chamber-js" */),
  "component---src-pages-chambers-js": () => import("./../../../src/pages/chambers.js" /* webpackChunkName: "component---src-pages-chambers-js" */),
  "component---src-pages-committees-js": () => import("./../../../src/pages/committees.js" /* webpackChunkName: "component---src-pages-committees-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legislator-js": () => import("./../../../src/pages/legislator.js" /* webpackChunkName: "component---src-pages-legislator-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-vote-js": () => import("./../../../src/pages/vote.js" /* webpackChunkName: "component---src-pages-vote-js" */),
  "component---src-pages-votes-js": () => import("./../../../src/pages/votes.js" /* webpackChunkName: "component---src-pages-votes-js" */)
}

